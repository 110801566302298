import React, { useEffect, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import LogoRodape from '../../components/LogoRodape';
import LogoNossaDefensoria from '../../components/LogoNossaDefensoria';
import BackgroundInicio from '../../components/BackgroundInicio';
import getValidationErrors from '../../utils/getValidationErrors';
import { AlertaConfirmacao } from '../../components/Alert';
import ToastIOS from '../../components/ToastIOS';

import {
  ContainerOpcoes,
  ContainerForm,
  LinkEsqueciSenha,
  TextEsqueciSenha,
  ViewEsqueciSenha,
  LinkFeedback,
  ViewFeedback,
  TextFeedback
} from './styles';
import { EMAIL_INVALIDO, CAMPO_OBRIGATORIO } from '../../constants/Validation';
import { useAuth } from '../../contexts/auth';
import { useAddToHomescreenPrompt } from "../../hooks/addToHomescreenPrompt";

interface SignInFormData {
  username: string;
  password: string;
}

const Entrar: React.FC = () => {
  const { login, loading, pendingValidationCode } = useAuth();
  const [ prompt, promptToInstall ] = useAddToHomescreenPrompt();
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const [ showIOSToast, setShowIOSToast ] = useState<boolean>(false);

  const formRef = useRef<FormHandles>(null);
  const { navigate } = useNavigation();

  const handleSignIn = async (data: SignInFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        username: Yup.string().email(EMAIL_INVALIDO).required(CAMPO_OBRIGATORIO),
        password: Yup.string().required(CAMPO_OBRIGATORIO),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { username, password } = data;

      login(username, password);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      };
    };
  };

  useEffect(() => {
    if(pendingValidationCode) {
      setTimeout(() => {
        navigate('LoginCodigo');
      }, 500)
    }
  }, [pendingValidationCode])

  useEffect(() => {
    if(prompt) {
      setShowAlert(true);
    }
  }, [prompt])

  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    }
    // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;
    if (isIos() && !isInStandaloneMode()) {
      setShowIOSToast(true);
    }
  }, [])

  return (
    <>
      <BackgroundInicio>
        <ContainerForm>
          <LogoNossaDefensoria tamanhoImagem={80}  tamanhoTexto='30px' />
          <Form ref={formRef} onSubmit={handleSignIn}>
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="username"
              placeholder="Email"
              accessible
              fundoEscuro
              onChangeText={(text) => formRef.current?.setFieldValue('username', text.trim())}
            />
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="password"
              placeholder="Senha"
              accessible
              passwordInput
              fundoEscuro
            />
            <Button
              onPress={() => {
                formRef.current?.submitForm();
              }}
            >
              Entrar
            </Button>
          </Form>
          <ContainerOpcoes>
            <LinkEsqueciSenha onPress={() => navigate('RecuperarSenha')}>
              <ViewEsqueciSenha>
                <TextEsqueciSenha>Esqueci a senha</TextEsqueciSenha>
              </ViewEsqueciSenha>
            </LinkEsqueciSenha>
            <Button btnSecundario onPress={() => navigate('Cadastrar')}>Cadastre-se</Button>
            <LinkFeedback onPress={() => navigate('Feedback', { local: 0 })}>
              <ViewFeedback>
                <TextFeedback>Avalie o nosso aplicativo</TextFeedback>
              </ViewFeedback>
            </LinkFeedback>
          </ContainerOpcoes>
        </ContainerForm>
        <LogoRodape />
        <Loading show={loading}/>
      </BackgroundInicio>
      <AlertaConfirmacao 
        titulo="Instalação"
        mensagem="Gostaria de adicionar o nosso aplicativo na sua tela inicial?"
        show={showAlert}
        onCancel={() => setShowAlert(false)}
        onConfirm={() => {
          setShowAlert(false);
          promptToInstall();
        }}
      />
      <ToastIOS show={showIOSToast} handle={() => setShowIOSToast(false)}/>
    </>
  );
};

export default Entrar;
